import React, { useState, useEffect, useContext } from 'react'
import styled from '@emotion/styled'
import Shape from 'shape-library'

import { useAuth0 } from '../react-auth0-spa'
import UserContext from '../context/user/userContext'
import Layout from '../components/Layout'
import DownloadFluentWorlds from '../components/DownloadFluentWorlds'
import Subscriptions from '../components/Subscriptions/Subscriptions'
import Loading from '../components/Loading'

const AccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 992px) {
    margin-top: -15rem;
  }
`

const UpdateUserNameContainer = styled.div`
  display: flex;
`

const UserSettingsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`

const UserSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ShapeContainer = styled.div`
  align-self: flex-end;
  width: 500px;
  height: 500px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`

const Profile = () => {
  const { auth0Loading, isAuthenticated } = useAuth0()

  const {
    updateUserName,
    username,
    productAccess,
    fetchUserProductAccess,
    userStateLoading,
  } = useContext(UserContext)
  const [input, setInput] = useState()

  useEffect(() => {
    if (!auth0Loading && isAuthenticated) {
      fetchUserProductAccess()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0Loading])

  const handleInputChange = e =>
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value,
    })

  const isAccountStateLoading =
    auth0Loading || userStateLoading || !productAccess

  return (
    <Layout>
      {isAccountStateLoading && <Loading />}
      {!isAccountStateLoading && (
        <AccountContainer>
          <DownloadFluentWorlds />
          {productAccess && !productAccess.fluentworlds ? (
            <h1
              css={`
                text-align: center;
              `}
            >
              Elija su suscripción ideal a continuación
            </h1>
          ) : (
            <h1
              css={`
                text-align: center;
              `}
            >
              Administrar Suscripciones
            </h1>
          )}
          <Subscriptions />
          <h1
            css={`
              text-align: center;
              margin-bottom: 1rem;
            `}
          >
            Configuraciones de la cuenta
          </h1>
          <UserSettingsWrapper>
            <UserSettingsContainer>
              <UpdateUserNameContainer>
                <input
                  type="text"
                  name="username"
                  placeholder={username}
                  onChange={handleInputChange}
                  css={`
                    margin-right: 1rem;
                    border-radius: 0.5rem;
                  `}
                />
                <button
                  className="btn-primary"
                  onClick={() => {
                    if (input && input.username && input.username !== '') {
                      updateUserName(input.username)
                    } else {
                      window.alert(
                        'Por favor, introduzca un nuevo nombre de usuario',
                      )
                    }
                  }}
                >
                  Actualizar nombre de usuario
                </button>
              </UpdateUserNameContainer>
            </UserSettingsContainer>
            <ShapeContainer>
              <Shape
                type="animatedillustrations"
                category="Abstract"
                name="Settings"
                primaryColor="#4A90E2"
                secondaryColor="#CACACA"
                size={500}
              />
            </ShapeContainer>
          </UserSettingsWrapper>
        </AccountContainer>
      )}
    </Layout>
  )
}

export default Profile
