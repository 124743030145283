import React from 'react'
import styled from '@emotion/styled'

import macosButtonImg from '../images/png/blue-buttons-mac.png'
import windowsButtonImg from '../images/png/blue-buttons-win.png'

const androidButtonImg =
  'https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png'
const iosButtonImg =
  'https://linkmaker.itunes.apple.com/images/badges/en-us/badge_appstore-lrg.svg'

const ANDROID_PLATFORM = 'android'
const IOS_PLATFORM = 'ios'
const MACOS_PLATFORM = 'mac'
const WINDOWS_PLATFORM = 'windows'
const OTHER_PLATFORM = 'other'

const IOS_SUPPORTED_PLATFORMS = ['iPhone', 'iPad', 'iPod']
const MACOS_SUPPORTED_PLATFORMS = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
const WINDOWS_SUPPORTED_PLATFORMS = ['Win32', 'Win64', 'Windows', 'WinCE']

const ANDROID_DOWNLOAD_LINK =
  'https://play.google.com/store/apps/details?id=com.threed_view.FluentWorlds&hl=en_US&utm_source=fluentworlds'
const IOS_DOWNLOAD_LINK =
  'https://apps.apple.com/us/app/learn-english-fluentworlds-esl/id1047012845?mt=8'
const MACOS_DOWNLOAD_LINK =
  'https://s3.us-west-2.amazonaws.com/downloads.fluentworlds.com/mac/FluentWorlds.dmg'
const WINDOWS_DOWNLOAD_LINK =
  'https://s3.us-west-2.amazonaws.com/downloads.fluentworlds.com/windows/SetupFluentWorlds.exe'

const DownloadContainer = styled.div`
  text-align: center;
  margin: 2rem;
`

const DownloadButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2rem;
`

const DownloadFluentWorlds = () => {
  const getOS = () => {
    const { userAgent } = window.navigator
    const { platform } = window.navigator

    let os = null

    if (MACOS_SUPPORTED_PLATFORMS.indexOf(platform) !== -1) {
      os = MACOS_PLATFORM
    } else if (IOS_SUPPORTED_PLATFORMS.indexOf(platform) !== -1) {
      os = IOS_PLATFORM
    } else if (WINDOWS_SUPPORTED_PLATFORMS.indexOf(platform) !== -1) {
      os = WINDOWS_PLATFORM
    } else if (/Android/.test(userAgent)) {
      os = ANDROID_PLATFORM
    } else {
      os = OTHER_PLATFORM
    }

    return os
  }

  const platform = getOS()

  return (
    <DownloadContainer>
      <h1>Descargue la aplicación FluentWorlds</h1>
      <DownloadButtonsContainer>
        {IOS_PLATFORM === platform || OTHER_PLATFORM === platform ? (
          <a
            href={IOS_DOWNLOAD_LINK}
            css={`
              background: url(${iosButtonImg}) no-repeat;
              background-size: 87% 87%;
              background-position: center center;
              display: inline-block;
              overflow: hidden;
              text-decoration: none;
              width: 255px;
              height: 75px;
            `}
            target="_blank"
            rel="noopener noreferrer"
            alt="Download on the App Store"
          >
            {' '}
          </a>
        ) : null}

        {ANDROID_PLATFORM === platform || OTHER_PLATFORM === platform ? (
          <a
            href={ANDROID_DOWNLOAD_LINK}
            css={`
              background: url(${androidButtonImg}) no-repeat;
              background-size: cover;
              background-position: center center;
              display: inline-block;
              overflow: hidden;
              text-decoration: none;
              width: 255px;
              height: 75px;
            `}
            target="_blank"
            rel="noopener noreferrer"
            alt="Get it on Google Play"
          >
            {' '}
          </a>
        ) : null}

        {WINDOWS_PLATFORM === platform || OTHER_PLATFORM === platform ? (
          <a
            href={WINDOWS_DOWNLOAD_LINK}
            css={`
              background: url(${windowsButtonImg}) no-repeat;
              background-size: 87% 87%;
              background-position: center center;
              display: inline-block;
              overflow: hidden;
              text-decoration: none;
              width: 255px;
              height: 75px;
            `}
            target="_blank"
            rel="noopener noreferrer"
            alt="Download for Windows"
          >
            {' '}
          </a>
        ) : null}
        {MACOS_PLATFORM === platform || OTHER_PLATFORM === platform ? (
          <a
            href={MACOS_DOWNLOAD_LINK}
            css={`
              background: url(${macosButtonImg}) no-repeat;
              background-size: 87% 87%;
              background-position: center center;
              display: inline-block;
              overflow: hidden;
              text-decoration: none;
              width: 255px;
              height: 75px;
            `}
            target="_blank"
            rel="noopener noreferrer"
            alt="Download for Mac OSX"
          >
            {' '}
          </a>
        ) : null}
      </DownloadButtonsContainer>
    </DownloadContainer>
  )
}

export default DownloadFluentWorlds
