import React from 'react'
import styled from '@emotion/styled'
import Lottie from 'react-lottie'

import LoadingAnimation from './LoadingAnimation'

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  margin-top: 10vh;
`

const Loading = () => {
  return (
    <LoadingContainer>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: LoadingAnimation,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={100}
        width={100}
      />
    </LoadingContainer>
  )
}

export default Loading
